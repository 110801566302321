var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.samplings,"search":_vm.search,"sort-by":"transference","loading":_vm.loading,"loading-text":"Consultando datos... Espere por favor"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.edit(item)}}},'v-icon',attrs,false),on),[_vm._v("fas fa-edit")])]}}],null,true)},[_c('span',[_vm._v("Completar Pago")])])]}},{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.datetime))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total))+" ")]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialogUpload),callback:function ($$v) {_vm.dialogUpload=$$v},expression:"dialogUpload"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer')],1),_c('v-card-text',[_c('div',{staticClass:"detail"},[_c('p',[_c('b',[_vm._v("RTN:")]),_vm._v(" "+_vm._s(_vm.item.rtn)+" ")]),_c('p',[_c('b',[_vm._v("Productor:")]),_vm._v(" "+_vm._s(_vm.item.nombre)+" ")]),_c('p',[_c('b',[_vm._v("#Factura:")]),_vm._v(" "+_vm._s(_vm.item.bill)+" ")])]),_c('div',{staticClass:"footer"},[_c('p',[_c('b',[_vm._v("Total:")]),_vm._v(" "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.item.total)).toFixed(2)))+" ")])])]),_c('v-card-actions',[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"gray"},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0 white--text",attrs:{"loading":_vm.loading1,"disabled":_vm.loading1,"large":"","rounded":"","color":"success"},on:{"click":_vm.update}},[_vm._v("Completar ")])],1)],1)],1)],1)]},proxy:true}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"gray--text"},[_c('th',{staticClass:"title",attrs:{"colspan":"6"}},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_vm._v(" Pendiente a Pagar: "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.sumField('total'))).toFixed(2)))+" ")])],1)],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }